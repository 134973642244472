<template>
    <div class="container text-center">
        <div class="row">
            <div class="d-flex">
                <img :src="require('../assets/logo.png')" style="width: 100px;" class="mx-auto" alt="">
            </div>          
        </div>  
    </div>
</template>

<script>
    export default {
        name: 'Logo'
    }
</script>